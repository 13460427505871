import React from "react"
import { navigateTo } from "gatsby-link"

import styles from './contact-form.module.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <div className={styles.contactForm}>
        <h3 className="section-title">Get In Touch</h3>
        <form
          name="contact"
          action="https://formspree.io/jpwoodson13@yahoo.com"
          method="POST"
        >
          <p>
            <label>
              Your name: <span className="input-required">*</span><br />
              <input required type="text" name="name" onChange={this.handleChange} />
            </label>
          </p>
          <p>
            <label>
              Your email: <span className="input-required">*</span><br />
              <input required type="email" name="email" onChange={this.handleChange} />
            </label>
          </p>
          <p>
            <label>
              Your message: <span className="input-required">*</span><br />
              <textarea required name="message" onChange={this.handleChange} />
            </label>
          </p>
          <p>
            <button type="submit" value="Send">Send</button>
          </p>
          <input type="hidden" name="_next" value="/contact-thanks?no-cache=1" />
          <input type="hidden" name="_replyto" value={this.state.email} />
        </form>
      </div>
    );
  }
}
