import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import ContactForm from './contact-form'
import ContactInfo from './contact-info'
import Footer from './footer'
import GraphicBlocks from './graphic-blocks'
import Header from './header'
import Intro from './intro'
import NewsArticles from './news-articles'
import Testimonials from './testimonials'
import WorkMap from './work-map'

import contactStyles from './contact.module.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query TitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        prismicHomepage {
          data {
            site_title {
              text
            }
            intro_title {
              text
            }
            intro_text {
              html
            }
            intro_image {
              url
            }
            text___graphic {
              graphic {
                url
              }
              heading {
                text
              }
              text {
                text
              }
            }
            testimonial {
              quote {
                text
              }
              client_name {
                text
              }
              image {
                url
              }
            }
            email {
              text
            }
            phone_number {
              text
            }
            work_address {
              latitude
              longitude
            }
            external_links {
              link_text {
                text
              }
              external_link {
                url
              }
            }
          }
        }
        allPrismicNewsArticles {
          edges {
            node {
              data {
                title {
                  text
                }
                article_url {
                  url
                }
                author
                description {
                  text
                }
                image {
                  url
                }
                list_order
              }
            }
          }
        }
      }
    `}
    render={data => {

      // Google Maps config
      const center = { lat: data.prismicHomepage.data.work_address.latitude, lng: data.prismicHomepage.data.work_address.longitude }
      const mapProps = {
        options: {
          center,
          zoom: 12,
          mapTypeControl: false,
          streetViewControl: false,
        },
        onMount: map => {
          new window.google.maps.Marker({
            position: center,
            map,
            title: "Amelie's Work Area",
          });

          new window.google.maps.Circle({
            strokeColor: '#F47215',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#F47215',
            fillOpacity: 0.35,
            map: map,
            radius: 1100,
            center: {
              lat: data.prismicHomepage.data.work_address.latitude,
              lng: data.prismicHomepage.data.work_address.longitude,
            },
          });
        },
      }

      return process.env.NODE_ENV === 'development' ? (
        <>
          <Helmet
            title={data.prismicHomepage.data.site_title.text}
            meta={[
              { name: 'description', content: 'Website of midwife Amelie Suermann' },
              { name: 'keywords', content: 'hebamme, hebammelie, amelie suermann, midwife, midwifery, berlin' },
            ]}
          >
            <html lang="en" />
          </Helmet>
          <Header siteTitle={data.prismicHomepage.data.site_title.text} />
          <Intro
            title={data.prismicHomepage.data.intro_title.text}
            text={data.prismicHomepage.data.intro_text.html}
            imageUrl={data.prismicHomepage.data.intro_image.url}
          />
          <GraphicBlocks blocks={data.prismicHomepage.data.text___graphic} />
          <div className="container">
            <div className="flex flex-sw">
              <div className="col-5">
                <ContactForm />
              </div>
              <div className="col-6">
                <NewsArticles articles={data.allPrismicNewsArticles.edges} />
              </div>
            </div>
          </div>
          <Testimonials testimonials={data.prismicHomepage.data.testimonial} />
          <div className={contactStyles.contactSection}>
            <div className="container">
              <div className="flex flex-nw">
                <ContactInfo email={data.prismicHomepage.data.email.text} phone_number={data.prismicHomepage.data.phone_number.text} external_links={data.prismicHomepage.data.external_links} />
                <WorkMap id="work-map" {...mapProps} />
              </div>
            </div>
          </div>
          {children}
          <Footer />
        </>
      ) : (
        <h1
          style={{ textAlign: 'center', marginTop: '100px' }}
        >under construction, please check back soon...</h1>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
