import React from 'react'
import Slider from "react-slick"

import styles from './testimonials.module.scss'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class Testimonials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSmallScreen: false //This is where I am having problems
    };

    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isSmallScreen: window.innerWidth < 1080 });
  }

  render() {
    const isSmallScreen = this.state.isSmallScreen;

    console.log(isSmallScreen)

    return (
      <div className={styles.testimonials}>
        <Slider {...this.settings}>
          {this.props.testimonials.map(testimonial => (
            <div key={testimonial.quote.text} className={`${styles.testimonialSlideContainer} flex center-center`}>
              <div className={styles.testimonialText}>
                <q>{isSmallScreen ? `${testimonial.quote.text.substring(0,115)}...` : testimonial.quote.text}</q>
                <p>{testimonial.client_name.text}</p>
              </div>
              <img src={testimonial.image.url} alt="testimonial background" />
            </div>
          ))}
        </Slider>
      </div>
    )
  }
}

export default Testimonials
